<template>
    <h1>Bienvenue sur lahouste.eu</h1>
  <router-view />
</template>

<style>
html {background-color: black}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #42b983;
}

#nav {
  display : block;
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.link
{
   color: #42b983;
   text-decoration: none; 
   background-color: none;
}
</style>
